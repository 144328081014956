import React, { useState, useEffect } from 'react';
import Hero from '../sections/Hero';
import About from '../sections/About';
import Treatmant from '../sections/Treatment';
import Relax from '../sections/Relax';
import Worksite from '../sections/Worksite';
import Vouchers from '../sections/Vouchers';
import Footer from '../sections/Footer';
import Modal from '../sections/Modal';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Seo from '../components/Seo';
import Navigation from '../components/Navigation';

gsap.registerPlugin(ScrollTrigger);
const IndexPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  // useEffect(() => {
  //   const elements = document.querySelectorAll('.animation');
  //   elements.forEach((el, index) => {
  //     ScrollTrigger.matchMedia({
  //       // small
  //       '(max-width: 768px)': function () {
  //         gsap.fromTo(
  //           el,
  //           { x: '+=50', opacity: 0 },
  //           {
  //             x: 0,
  //             opacity: 1,
  //             duration: 0.7,
  //             scrollTrigger: {
  //               trigger: el,
  //               start: 'top 50%',
  //               once: true,
  //             },
  //           }
  //         );
  //       },
  //       '(min-width: 769px)': function () {
  //         gsap.fromTo(
  //           el,
  //           { x: '+=150', opacity: 0 },
  //           {
  //             x: 0,
  //             opacity: 1,
  //             duration: 1,
  //             scrollTrigger: {
  //               trigger: el,
  //               start: 'top 50%',
  //               once: true,
  //               // markers: true,
  //             },
  //           }
  //         );
  //       },
  //     });
  //   });
  // });
  useEffect(() => {
    const right = document.querySelectorAll('.right');
    const left = document.querySelectorAll('.left');
    const opacity = document.querySelectorAll('.animation');
    const rightPrice = document.querySelectorAll('.info.righta');
    const leftPrice = document.querySelectorAll('.price-container.lefta');
    left.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        '(max-width: 768px)': function () {
          gsap.fromTo(
            el,
            { x: '+50', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: 'top 60%',
                once: true,
                // markers: true,
              },
            }
          );
        },
        '(min-width: 769px)': function () {
          gsap.fromTo(
            el,
            { x: '+=150', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: el,
                start: 'top 40%',
                once: true,
              },
            }
          );
        },
      });
    });
    right.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        '(max-width: 768px)': function () {
          gsap.fromTo(
            el,
            { x: '-=50', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: 'top 60%',
                once: true,
              },
            }
          );
        },
        '(min-width: 769px)': function () {
          gsap.fromTo(
            el,
            { x: '-=150', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: el,
                start: 'top 40%',
                once: true,
                // markers: true,
              },
            }
          );
        },
      });
    });
    leftPrice.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        '(max-width: 768px)': function () {
          gsap.fromTo(
            el,
            { x: '+=50', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: 'top 60%',
                once: true,
                // markers: true,
              },
            }
          );
        },
        '(min-width: 769px)': function () {
          gsap.fromTo(
            el,
            { x: '+=150', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: el,
                start: 'top 75%',
                once: true,
                // markers: true,
              },
            }
          );
        },
      });
    });
    rightPrice.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        '(max-width: 768px)': function () {
          gsap.fromTo(
            el,
            { x: '-=50', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: 'top 60%',
                once: true,
              },
            }
          );
        },
        '(min-width: 769px)': function () {
          gsap.fromTo(
            el,
            { x: '-=150', opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: el,
                start: 'top 75%',
                once: true,
                // markers: true,
              },
            }
          );
        },
      });
    });
    opacity.forEach((el, index) => {
      ScrollTrigger.matchMedia({
        // small
        '(max-width: 768px)': function () {
          gsap.fromTo(
            el,
            { y: '+=50', opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.7,
              scrollTrigger: {
                trigger: el,
                start: 'top 70%',
                once: true,
                // markers: true,
              },
            }
          );
        },
        '(min-width: 769px)': function () {
          gsap.fromTo(
            el,
            { y: '+=150', opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: el,
                start: 'top 90%',
                once: true,
                // markers: true,
              },
            }
          );
        },
      });
    });
  });
  // useEffect(() => {
  //   const video = document.querySelector('video');
  //   // const videos = gsap.utils.toArray('video');
  //   ScrollTrigger.create({
  //     trigger: video,
  //     scrollTrigger: {
  //       trigger: video,
  //     },
  //     start: 'top center',
  //     end: 'bottom center',
  //     markers: true,
  //     onEnter: () => video.play(),
  //     onEnterBack: () => video.play(),
  //     onLeave: () => video.pause(),
  //     onLeaveBack: () => video.pause(),
  //   });
  // });
  const handleModalOpen = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const handleModalClose = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  };
  return (
    <>
      <Navigation modalOpen={handleModalOpen} />
      <Seo
        title={'Fizjoergo'}
        description={`Fizjoergo - firma zajmująca się masażem biurowym w Toruniu i okolicach.`}
        keywords={[
          'fizjoergo',
          'fizjoterapia',
          'fizjoterapia toruń',
          'ergonomnia',
          'masaż',
          'biurowy',
          'toruń',
        ]}
      />
      <div className='content'>
        <Hero />
        <About />
        <Treatmant modalOpen={handleModalOpen} />
        <Relax modalOpen={handleModalOpen} />
        <Worksite modalOpen={handleModalOpen} />
        <Vouchers modalOpen={handleModalOpen} />
        <Footer />
        {modalOpen && <Modal modalClose={handleModalClose} />}
      </div>
    </>
  );
};
export default IndexPage;

export function Head() {
  return (
    <>
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '885676322741724');
          fbq('track', 'PageView');
        `}
      </script>
      <script
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-W6GEGD2T4S'
      ></script>
      <script>
        {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-W6GEGD2T4S');
  `}
      </script>
      <noscript>
        {`
          <img
          height='1'
          width='1'
          style='display:none'
          src='https://www.facebook.com/tr?id=885676322741724&ev=PageView&noscript=1'
        />
          `}
      </noscript>
      <script>
        {`

          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-THB7WD3');

        `}

        {/* Manager */}
      </script>
    </>
  );
}
