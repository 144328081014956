import React from 'react';
import HeaderTitle from '../components/HeaderTittle';
import areaTreatment from '../images/area-treatment.svg';
import '../styles/price.scss';
import Button from '../components/Button';
import logo from '../images/logoN.svg';
import pzu from '../images/pzu.svg';

const Vouchers = (props) => {
  return (
    <section className='container voucher-section'>
      <HeaderTitle>VOUCHERY</HeaderTitle>
      <p className='subtitle'>Oferujemy w sprzedaży okazjonalne vouchery.</p>
      <p className='desc-main container'>
        Zapytaj o ofertę przed: Świętami Bożego Narodzenia, Świętami
        Wielkanocnymi, Dniem Babci i Dziadka, Dniem Kobiet, Dniem Matki.
      </p>
      <div className='voucher-container '>
        <div className='voucher voucher-logo'>
          <img width height src={logo} alt='logo' />
          <p className='name'>VOUCHER</p>
        </div>
        <div className='voucher voucher-empty'></div>
      </div>
      <section id='pzu'>
        <HeaderTitle>NASI PARTNERZY</HeaderTitle>
        <div className='pzu-container'>
          <img width height src={pzu} alt='pzu' />
        </div>
        <p className='desc-main container partners'>
          Obecnie współpracujemy z PZU Zdrowie. <br />
          Aby zarejestrować się na pierwszą wizytę w ramach pakietu PZU Zdrowie
          skontaktuj się z nami TELEFONICZNIE lub bezpośrednio z PZU Zdrowie.
        </p>
        <div className='button-container'>
          <a className='pzu-link' href='tel:+48518359935'>
            <Button hoverText='Marcin Zaręba    518 359 935'>
              Zadzwoń i zarezerwuj
            </Button>
          </a>
        </div>
      </section>
    </section>
  );
};

export default Vouchers;
