import React from 'react';
import '../styles/areaItem.scss';
import Square from './Square';

const AreaItem = (props) => {
  return (
    <div onClick={props.click} className='area-item'>
      <Square img={props.img} />
      <span title={props.children}> {props.children}</span>
    </div>
  );
};

export default AreaItem;
