import React from 'react';
import Image from 'gatsby-image';
import '../styles/aboutSingleItem.scss';
import Button from './Button';

const AboutSingleItem = (props) => {
  return (
    <div id={props.id} className='about-single-item'>
      <div
        className={props.reverse ? 'img-container left' : 'img-container right'}
      >
        <Image fluid={props.img}/>
      </div>
      <div
        className={
          props.reverse ? 'text-container right' : 'text-container left'
        }
      >
        <h3 className='name'>{props.data.name}</h3>
        <p className='main'>{props.data.main}</p>
        <p className='desc'>{props.data.desc1}</p>
        {props.data.desc2 ?
          <p className='desc'>{props.data.desc2}</p>
          :
          null
        }

        {props.data.training ? (
          <>
            <p className='desc training'>{props.data.desc3}</p>
            <ul>
              {props.data.training.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        ) : null}

        {props.data.link && (
          <div className='button-container'>
            <Button link={props.data.link}>umów wizytę</Button>
          </div>
        )}


      </div>
    </div>
  );
};
export default AboutSingleItem;
