import React from 'react';
import '../styles/hero.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import logo from '../images/logo.svg';
import logoN from '../images/logon.svg';
import AreaItem from '../components/AreaItem';
import areaTreatment from '../images/area-treatment.svg';
import areaRelax from '../images/area-relax.svg';
import areaWorksite from '../images/office.svg';
import Button from '../components/Button';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
};

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      hero0: file(name: { eq: "hero00" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hero1: file(name: { eq: "hero1" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hero2: file(name: { eq: "hero2" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hero3: file(name: { eq: "hero3" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      hero4: file(name: { eq: "hero4" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hero5: file(name: { eq: "hero5" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const handleMenuItem = (section) => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: section,
      offsetY: 50,
    });
  };

  return (
    <header>
      <div className='hero container'>
        <div className='slider'>
          <Slider {...settings}>
            <div className='overflow'>
              <div className='slider-item slider-item-0'>
                <Image fluid={data.hero0.childImageSharp.fluid} />
              </div>
            </div>
            <div className='overflow'>
              <div className='slider-item slider-item-1'>
                <Image fluid={data.hero1.childImageSharp.fluid} />
              </div>
            </div>
            <div className='overflow'>
              <div className='slider-item slider-item-2'>
                <Image fluid={data.hero2.childImageSharp.fluid} />
              </div>
            </div>
            <div className='slider-item slider-item-3'>
              <Image fluid={data.hero3.childImageSharp.fluid} />
            </div>
            <div className='slider-item no-padding slider-item-4'>
              <Image fluid={data.hero4.childImageSharp.fluid} />
            </div>
            <div className='slider-item no-padding slider-item-5'>
              <Image fluid={data.hero5.childImageSharp.fluid} />
            </div>
          </Slider>
        </div>
        <div className='logo-container'>
          <div className='box-shadow'>
            <img src={logoN} alt='logo' />
          </div>
        </div>
      </div>
      <div className='areas'>
        <AreaItem click={() => handleMenuItem('#leczenie')} img={areaTreatment}>
          Strefa leczenia
        </AreaItem>
        <AreaItem click={() => handleMenuItem('#relax')} img={areaRelax}>
          Strefa relaksu
        </AreaItem>
        <AreaItem click={() => handleMenuItem('#worksite')} img={areaWorksite}>
          Strefa worksite
        </AreaItem>
      </div>
      <div className='button-container'>
        <Button scroll={'#leczenie'}>pokaż cennik</Button>
      </div>
    </header>
  );
};

export default Hero;
