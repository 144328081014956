import React from 'react';
import Square from './Square';
import '../styles/headerTittle.scss';

const HeaderTitle = (props) => {
  return (
    <div className='header-title animation'>
      {props.img && <Square img={props.img} />}
      <h2 title={props.children}> {props.children}</h2>
    </div>
  );
};

export default HeaderTitle;
