import React from 'react';
import HeaderTitle from '../components/HeaderTittle';
import areaTreatment from '../images/office.svg';
import '../styles/price.scss';
import Button from '../components/Button';

const Worksite = (props) => {
  return (
    <section id='worksite' className='container worksite-section'>
      <HeaderTitle img={areaTreatment}>Strefa worksite</HeaderTitle>
      <p className='subtitle'>
        Strefa Worksite, to coś dla Twojej firmy i jej pracowników.
      </p>
      <p className='desc-main container'>
        To między innymi masaż, który stanowi świetną i bardzo przyjemną formę
        terapii i profilaktyki w miejscu pracy, praktycznie bez przygotowań ze
        strony osoby masowanej. Masaż odbywa się na specjalnym krześle, w
        ubraniu i trwa około 15 minut. Ten kwadrans wystarcza, żeby tchnąć w
        pracownika mnóstwo pozytywnej energii i chęci do pracy. To taka „zdrowa
        pigułka regeneracji”. Stanowi również świetny dodatek do konferencji czy
        spotkań biznesowych.
      </p>
      <p className='desc-main container'>
        Jeśli chcesz zadbać bardziej o zdrowie swojej kadry, możesz zaprosić nas
        również do swojej firmy lub przysyłać do nas pracowników. Przeprowadzimy
        wówczas diagnostykę fizjoterapeutyczną i zaproponujemy spersonalizowany
        plan terapii - skrojony na miarę potrzeb konkretnej firmy. Cena jest
        uzależniona od wielkości zamówienia oraz kosztów dojazdu.
      </p>

      <div onClick={props.modalOpen} className='button-container'>
        <Button>Skontaktuj się z nami</Button>
      </div>
    </section>
  );
};

export default Worksite;
