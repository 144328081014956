import React, { useState } from 'react';
import '../styles/priceItem.scss';

const PriceItem = (props) => {
  const [isInfoVisable, setInfoIsVisable] = useState(false);
  const [isPackagesVisable, setPackagesVisable] = useState(false);
  const { name, additional, additionalExt, price, packages, maxHeight } =
    props.item;
  const handleInfoVisable = () => {
    setInfoIsVisable((prevState) => !prevState);
  };
  const handlePackagesVisable = () => {
    setPackagesVisable((prevState) => !prevState);
  };
  const toggleTextInfo = () => {
    if (isInfoVisable) {
      return 'czytaj mniej <';
    }
    return 'czytaj więcej >';
  };
  const toggleTextPackages = () => {
    if (isPackagesVisable) {
      return 'pokaż pakiety <';
    }
    return 'pokaż pakiety >';
  };
  const maxHeightStyle = () => {
    if (isPackagesVisable) {
      return '500px';
    }
    return '100px';
  };
  return (
    <div className={isInfoVisable ? 'price-item open' : 'price-item'}>
      <div className='info righta'>
        <p className='name'>{name} </p>
        {additional && (
          <>
            <p onClick={handleInfoVisable} className='show-more'>
              {toggleTextInfo()}
            </p>
            {true && (
              <div className='collapsed'>
                <p className='desc'>{additional}</p>
                {additionalExt && <p className='desc'>{additionalExt}</p>}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={
          isPackagesVisable
            ? 'price-container lefta open'
            : 'price-container lefta'
        }
      >
        {price ? (
          <>
            {price.map((item, index) => (
              <div key={index} className='single-item-main'>
                <span className='time'>{item.time}</span>
                <span
                  className={
                    item.time ? 'single-price' : 'single-price price-only'
                  }
                >
                  {item.price}
                </span>
              </div>
            ))}
          </>
        ) : (
          <span className='single-item individual'>
            Cena ustalana indywidualnie
          </span>
        )}

        {packages && (
          <div className='packages-container'>
            <p onClick={handlePackagesVisable} className='show-more'>
              {toggleTextPackages()}
            </p>
            <div className='collapsed'>
              <p className='name'>Pakiety:</p>
              {packages.map((item, index) => (
                <div className='parts' key={index}>
                  {item.name && <div className='part-name'>{item.name}</div>}

                  {item.content.map((item, index) => (
                    <div key={index}>
                      <div className='single-item'>
                        <span className='time'>{item.amount}</span>
                        <span className='single-price'>{item.price}</span>
                      </div>
                      {item.savings && (
                        <div className='savings'>
                          Oszczędzasz {item.savings}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PriceItem;
