import React from 'react';
import '../styles/square.scss';

const Square = (props) => {
  return (
    <div className='square'>
      <div className='border'></div>
      <img width height src={props.img} alt='' />
    </div>
  );
};

export default Square;
