import React from 'react';
import HeaderTitle from '../components/HeaderTittle';
import areaRelax from '../images/area-relax.svg';
import '../styles/price.scss';
import PriceItem from '../components/PriceItem';
import JSONData from '../../content/relax.json';
import Button from '../components/Button';

const Relax = (props) => {
  return (
    <section id='relax' className='container price-section relax'>
      <HeaderTitle img={areaRelax}>Strefa relaksu</HeaderTitle>
      <p className='desc-main container'>
        Nasza strefa relaksu to, jak przystało na fizjoterapeutów, połączenie
        metod nastawionych na efekt estetyczny, jak <br/> i zdrowotny. Zdobywane przez
        lata umiejętności oraz doświadczenie w pracy jako fizjoterapeuci,
        zaadaptowaliśmy również <br/> z sukcesem do naszego działu odnowy
        biologicznej.
      </p>

      <div className='price-container '>
        {JSONData.content.map((item, index) => {
          return <PriceItem key={index} item={item}/>;
        })}
      </div>
      <div onClick={props.modalOpen} className='button-container'>
        <Button>umów wizytę</Button>
      </div>
    </section>
  );
};
export default Relax;
