import React from 'react';
import HeaderTitle from '../components/HeaderTittle';
import areaTreatment from '../images/area-treatment.svg';
import '../styles/price.scss';
import PriceItem from '../components/PriceItem';
import JSONData from '../../content/treatment.json';
import Button from '../components/Button';

const Treatmant = (props) => {
  return (
    <section id='leczenie' className='container price-section treatment'>
      <HeaderTitle img={areaTreatment}>Strefa leczenia</HeaderTitle>
      <p className='desc-main container'>
        W naszym gabinecie staramy się zadbać o komfort pacjentów, dobierając
        metody terapii tak, aby były one jednocześnie skuteczne i jak najlepiej
        odbierane przez konkretną osobę. Nie każdy organizm jest taki sam.
        Dlatego poprzez wywiad, badanie i obserwację tego, jak przebiega
        terapia, indywidualnie opracowujemy program terapeutyczny.
      </p>
      <p className='desc-main container'>
        Wybierając się do nas na terapię przygotuj się na obszerny wywiad,
        zabierz swoje badania (jeśli takie posiadasz), ubierz się wygodnie i
        nastaw na efektywnie spędzony czas.
      </p>
      <div className='price-container'>
        {JSONData.content.map((item, index) => {
          return <PriceItem key={index} item={item} />;
        })}
      </div>
      <div onClick={props.modalOpen} className='button-container'>
        <Button>umów wizytę</Button>
      </div>
    </section>
  );
};

export default Treatmant;
